import React, { useState } from "react";
import Logo from "../Assets/Img/logo.svg";
import { FaBars, FaPowerOff, FaTimes } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import NavLinks from "../Global/NavLink";

import Connect from "./Connect";
// import { useEffect } from "react";
import Flag from "react-country-flags";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [colorChange, setColorchange] = useState(false);
  const [navLogo, setNavLogoChange] = useState(false);
  const [navMenuUp, setNavMenuUp] = useState(true);

  const changeNavbarColor = () => {
    if (window.scrollY > 0) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const changeLogoSize = () => {
    if (window.scrollY > 0) {
      setNavLogoChange(true);
    } else {
      setNavLogoChange(false);
    }

    if (window.scrollY > 0) {
      setNavMenuUp(false);
    } else {
      setNavMenuUp(true);
    }
  };

  window.addEventListener("scroll", changeNavbarColor);
  window.addEventListener("scroll", changeLogoSize);

  return (
    <>
      <IconContext.Provider value={{ color: "#" }}>
        <nav className={colorChange ? "navbar colorChange" : "navbar"}>
          <div
            className="navbar-container container lg:pl-20 lg:pr-20"
            style={{ maxWidth: "1400px", margin: "0 auto" }}
          >
            <div className={navLogo ? " navLogo" : "navbar-logo"}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " activated" : "")
                }
                onClick={closeMobileMenu}
              >
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            {/* <div className="flag lg:hidden">
              <a href="https://pt.algofame.org" className="flag">
                <Flag
                  country="pt"
                  asSquare={false}
                  style={{ height: "27px" }}
                />
              </a>
            </div> */}
            <div className={navMenuUp ? " navMenuUp" : "menu-icon"}>
              <div className="text-white" onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </div>
            </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <div className="triangle lg:hidden md:hidden">
                <BsTriangleFill />
              </div>
              <NavLinks />

              <li id="google_translate_element"></li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}
